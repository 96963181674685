import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout";
import SEO from "../components/seo";
import * as CONST from "../components/const";
const img_h = "../images/404_h.jpg";
const img_v = "../images/404_v.png";

function NotFoundPage() {
  const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

  return (
    <Layout>
      <SEO
        description="社名の由来は脳波のα波。脳波のα波は、私たちが得意なことや好きなことに集中しているときに強く観測されます。アルファ・ウェーブでは、心も体もリラックスし、かつ集中して仕事と遊びをしたい。そう考えているのです。"
        title="404: Not found" />
      <div>
        <StaticImage
          quality="100"
          alt=""
          placeholder={STATIC_IMAGE_PLACEHOLDER}
          layout="fullWidth"
          className={CONST.PC_SHOW_CLASS + " w-full"}
          src={img_h}
        />
        <StaticImage
          quality="100"
          alt=""
          placeholder={STATIC_IMAGE_PLACEHOLDER}
          layout="fullWidth"
          className={CONST.PC_HIDDEN_CLASS + " w-full"}
          src={img_v}
        />
      </div>
    </Layout>
  );
}

export default NotFoundPage;
